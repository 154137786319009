const API={
    queryResumeList: '/resumeController/queryResumeByForm',//简历列表post
    queryByResumeId:"/resumeController/queryByResumeId",//简历详情get
    industry:"/company/industry/selectAll",//行业列表get
    queryMemberNotPage:"/member/queryMemberNotPage",//简历搜索时的企业列表get
    queryIndustry:"/work/Industry/queryIndustry",//职位分类get
    oddJob:"/oddJob/queryAllOddJob",//零工post
    exportResumeList:"/resumeController/exportResumeList",//零工post
}

export default API