<template>
    <div class="resumeInfo" id="printMe">
        <div class="wz" >
            <div style="padding-top:4px;">
                <el-breadcrumb separator="/"></el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>简历详细</el-breadcrumb-item>
            </div>
            <el-button @click="goBack" icon="el-icon-back" size="small" style="float:right; margin-top:0px;">返回</el-button>
            
            
<!--            </el-breadcrumb>-->
        </div>
        <div v-if="isloaded">
        <el-card class="box-card" >
            <div class="user-info flex">
                <div class="headFigure">
                    <img :src="detail.resumeDO.picUrl" alt="" srcset="">
                </div>
                <div class="base-info">
                    <div class="certificate flex">
                        <span class="name">{{detail.resumeDO.name}}</span>
                        <!-- <span><i class="el-icon-mobile-phone"></i> 手机认证</span>
                        <span><i class="el-icon-folder-checked"></i> 身份认证</span> -->
                        <!-- <span><i class="el-icon-medal" style="color:#409eff; font-weight:bold;"></i> 普通人才</span>
                        <span><i class="el-icon-medal-1" style="color:#ff704f;font-weight:bold;"></i> 高级人才</span> -->
                    </div>
                    <div class="base-detail">
                        <span class="sex">{{detail.resumeDO.gender}} </span>
                        <span class="icon-split-small"> | </span>
                        <span class="age">出生于：{{detail.resumeDO.birthday}} </span>
                        <span class="icon-split-small"> | </span>
                        <span class="work1">{{detail.resumeDO.education}}</span>
                        <span class="icon-split-small"> | </span>
                        <span class="work1">{{detail.resumeDO.workExperience}}工作经验 </span>
                    </div>
                    <div class="expectInfo">
                        <div class="title-content flex">
                            <div class="item-title">求职状态:</div>
                            <div class="item-content">{{detail.resumeDO.nowStatus}}</div>
                        </div>
                        <div class="title-content flex">
                            <div class="item-title">期望职位:</div>
                            <div class="item-content">{{detail.resumeDO.expectPost}}</div>
                        </div>
                        <div class="title-content flex">
                            <div class="item-title">期望薪资:</div>
                            <div class="item-content">{{detail.resumeDO.expectSalary}}</div>
                        </div>
                        <div class="title-content flex">
                            <div class="item-title">联系电话:</div>
                            <div class="item-content" style="font-size:20px;color:#F56C6C;">{{detail.resumeDO.linkerPhone}}</div>
                        </div>
                        <div class="title-content flex">
                            <div class="item-title">现居地:</div>
                            <div class="item-content">{{detail.resumeDO.nativePlace}}</div>
                        </div>
                        <div class="title-content flex">
                            <div class="item-title">邮箱:</div>
                            <div class="item-content">{{detail.resumeDO.email}}</div>
                        </div>
                        <div class="title-content flex">
                            <div class="item-title">微信:</div>
                            <div class="item-content">{{detail.resumeDO.vxNumber}}</div>
                        </div>
                    </div>
                    <div class="getContact-row">
                        <!-- <el-button type="danger" class="active button mybotton">下载简历</el-button> -->
                        <a :href="detail.resumeDO.enclosure" target="_blank" style="display:inline-block; width:150px;line-height:38px; height:38px; text-align:center;border-radius: 4px;color:#ffffff;background:#404040;">下载简历</a>
                        <el-button style="margin-left:8px;" v-print="'#printMe'" icon="el-icon-printer" class="success button">打印简历</el-button>
                        <!-- <el-button class="success button">提升高级人才</el-button> -->
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card">
            <div class="industry">
                <div class="industry-title title1"><i></i>职业优势</div>
                <div class="tag-choose">
                    <el-tag v-for="(item,index) in tag" :key="index" type="primary" class="tagout">{{item}}</el-tag>
                </div>
            </div>
            <div class="work">
                <div class="title1"><i></i>工作经验 </div>
                <div class="work-detail" v-for="experienitem in detail.resumeWorkExperienceDOS" :key="experienitem.id">
                    <div class="itemName">{{experienitem.companyName}}</div>
                    <div class="project-content">
                        <p>工作时间：{{experienitem.startTime}} 至 {{experienitem.endTime}}</p>
                        <p>薪资水平：{{experienitem.salary}}</p>
                        <p>在职职位：{{experienitem.position}}</p>
                        <p>工作职责：<pre>{{experienitem.dutyContent}}</pre></p>
                    </div>
                </div>
            </div>
            
            <div class="education">
                <div class="title1"><i></i>教育经历 </div>
                <div class="edu-detail" v-for="edutionitem in detail.resumeEductionExperienceDO" :key="edutionitem.id">
                    <div class="school">
                        <span class="college-name">{{edutionitem.schoolName}}</span>
                        <span class="splitLine-edu">|</span>
                        <span class="graduate-time">{{edutionitem.startTime}} 至 {{edutionitem.endTime}}</span>
                    </div>
                    <div class="professional">{{edutionitem.major}}</div>
                </div>
            </div>
            <div class="work" >
                <div class="title1"><i></i>项目经验 </div>
                <div class="work-detail" v-for="projectitem in detail.resumeProjectExperienceDO" :key="projectitem.id">
                    <div class="itemName">{{projectitem.projectName}}</div>
                    <div class="project-content">
                        <p>项目时间：{{projectitem.startTime}} 至 {{projectitem.endTime}}</p>
                        <p>担任职位：{{projectitem.position}}</p>
                        <p>项目职责：<pre>{{projectitem.projectDesc}}</pre></p>
                    </div>
                </div>
            </div>
            <div class="aboutMe">
                <div class="title1"><i></i>自我评价 </div>
                <div class="about-detail">
                   <pre> {{detail.resumeDO.selfEvaluation}}</pre>
                </div>
            </div>
        </el-card>
        </div>
    </div>
</template>
<script>
import apiurl from '@/api/resume'
import _api from '@/api/index'
export default {
    data(){
        return{
            detail:{},
            isloaded:false,
            tag:[],
            id:"",
            type:1,
            page:1
        }
    },
    created(){
        this.id=this.$route.query.id
        this.type=this.$route.query.type
        this.page=this.$route.query.page
        this.getDeatil()
    },
    methods:{
        goBack(){
            this.$router.push({
                path: '/resume/index',
                query: {
                    type:this.type,
                    page:this.page
                }
            })
        },
        getDeatil(){
            _api.get(apiurl.queryByResumeId,{resumeId:this.id}).then(res=>{
                this.isloaded=true
                console.log(res)
                if(res.success){
                    this.detail=res.data
                    if(this.detail.resumeDO.tag!=null){
                        this.tag=this.detail.resumeDO.tag.split(",")//字符串转为数组
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/resume.css";
</style>
<style lang="scss" scoped>
.wz{ overflow: hidden;}
pre{white-space: pre-wrap;}
    .box-card{ margin-top: 15px;}
    .resumeInfo{
        height: 100%;
        .user-info{
            margin: 20px 10px;
            .headFigure{
                width: 110px;
                height: 110px;
                margin-right: 32px;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
            .base-info{
                color: #333;
                font-size: 14px;
                .certificate{
                    padding: 15px 0 6px 0;
                    span{
                        margin-right: 20px;
                        &.name{
                            font-weight: 700;
                        }
                    }
                    .el-icon-mobile-phone{
                        color: #f56c6c;
                        font-weight: 600;
                    }
                    .el-icon-folder-checked{
                        color: #5cb87a;
                        font-weight: 600;
                    }
                }
                .base-detail{
                    color: #666;
                    font-size: 14px;
                    margin: 22px 0 18px;
                    span{
                        margin-right: 15px;
                    }
                    .icon-split-small{
                        color: #dadada;
                        margin-right: 7px;
                    }
                }
                .expectInfo{
                    margin: 15px 0;
                    .title-content{
                        font-size: 14px;
                        line-height: 34px;
                        .item-title{
                            color: #999;
                        }
                        .item-content{
                            color: #333;
                            margin-left: 10px;
                        }
                    }
                }
                .getContact-row{
                    button{
                        width: 150px;
                    }
                }
            }
        }
        .title1{
            font-size: 20px;
            color: #333333;
            margin-bottom: 41px;
            font-weight: bold;
            i{
                display: inline-block;
                width: 5px;
                height: 18px;
                background: #ff704f;
                vertical-align: middle;
                margin-top:-3px;
                margin-right:8px;
            }
        }
        .industry{
            margin: 20px 10px;
            border-bottom: 1px solid #eee;
            .industry-title{
                font-size: 20px;
                color: #333333;
                margin-bottom: 41px;
                font-weight: bold;
            }
            .tag-choose{
                margin-bottom: 20px;
                .el-tag{
                    margin: 0 10px 10px 0;
                }
            }
        }
        .work{
            margin: 20px 10px;
            border-bottom: 1px solid #eee;
           .work-detail{
               .itemName{
                    font-size: 16px;
                    line-height: 24px;
                    color: #000;
                    font-weight: bold;
                    margin-bottom: 10px;
               }
               .project-content{
                    margin-bottom: 20px;
                    p{
                        font-size: 14px;
                        line-height: 24px;
                        color: #333;
                        margin-bottom: 10px;
                    }
               }
           }
            
        }
        .education{
            margin: 20px 10px;
            border-bottom: 1px solid #eee;
            .edu-detail{
                margin-bottom: 25px;
                .school{
                    margin-bottom: 5px;
                    font-size: 14px;
                .college-name{
                    font-weight: bold;
                    color: #000;
                } 
                .splitLine-edu{
                    margin: 0 66px;
                    color: #d5d5d5;
                }
                .graduate-time{
                    color: #999;
                }
                }
                .professional{
                    margin: 8px 0;
                color: #000;
                }
            }
        }
        .aboutMe{
            margin: 20px 10px;
            border-bottom: 1px solid #eee;
            .about-detail{
                margin-bottom: 20px;
                font-size: 14px;
                color: #333;
            }
        }

    }
</style>