import { render, staticRenderFns } from "./Show.vue?vue&type=template&id=39ab1425&scoped=true&"
import script from "./Show.vue?vue&type=script&lang=js&"
export * from "./Show.vue?vue&type=script&lang=js&"
import style0 from "./Show.vue?vue&type=style&index=0&id=39ab1425&prod&scoped=true&lang=css&"
import style1 from "./Show.vue?vue&type=style&index=1&id=39ab1425&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ab1425",
  null
  
)

export default component.exports